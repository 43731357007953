import React from 'react';
import {projects} from "../data/projects";


export function ProjectList({onClick}) {
    let list = [];
    const keys = Object.keys(projects);

    for (const key of keys) {
        const project = projects[key];
        list.push(
            <div
                key={key}
                className={"oneProject"}
                onClick={()=>onClick(project)}
                style={{backgroundImage: "url('/img/projects/"+project.image+"')"}}
            >
                <div>
                    <h3>{project.name}</h3>
                    <small>{project.description}</small>
                </div>
            </div>)
    }
    return <div id={"itemWrap"}><h1>Projecten</h1>{list}</div>;

}