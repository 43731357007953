import React from 'react';

export class ActiveProduct extends React.Component {
    render() {
        const project = this.props.project;
        return (
            <div id={"activeProduct"}>
                <h1>{project.name}</h1>

                <div id={"AP_preview"} style={{backgroundImage: "url('/img/projects/"+project.image+"')"}} />
                <p id={"AP_linkWrap"}><a href={project.url} target={"_blank"}>Bekijk '{project.name}' in een nieuw tabblad</a></p>

                <div id={"AP_content"}>{project.content}</div>
            </div>
        );
    }
}