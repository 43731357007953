import React from 'react';

let i = 0;
export const projects = {
        compliment: {
            name: "Krijg Een Complimentje",
            url: "https://krijgeencomplimentje.nl",
            image: "compliment.png",
            description: "Oefensite om server aan de praat te krijgen",
            content: <>
                <p key={i++}>De site waar je nu naar kijkt staat op een eigen VPS. Ik heb nog nooit een server vanaf het
                    begin in m'n
                    eentje in elkaar gezet, dus ben dat gaan doen.</p>
                <p key={i++}>Deze site heeft wat verschillende technieken zoals ReactJS, npm, mysql database en apache
                    nodig, dat ik
                    allemaal op deze server ben gaan installeren.</p>
                <p key={i++}>Omdat het makkelijker is om ergens naartoe te werken, heb ik een oud idee overnieuw gemaakt. De
                    mensen
                    waarvan ik de reactie meemaak hebben allemaal een glimlachje, wat voor dit project een prima
                    eindresultaat is.</p>
            </>
        },
        onspassievehuis: {
            name: "Ons Passive huis (blog)",
            url: "https://onspassievehuis.blogspot.com",
            image: "onspassievehuis.png",
            description: "Blog over het laten bouwen van ons eigen huis",
            content: <>
                <p>Ik ben samen met mijn vriendin bezig met het (laten) ontwerpen/ bouwen van ons eigen huis. Ons doel is zo
                    duurzaam als ons budget toelaat (wat een lastige uitdaging blijkt).</p>
                <p>We hebben besloten een blogje bij te houden over de dingen die we daarmee ervaren. Deels doen we dat
                    zodat we later terug kunnen kijken naar hoe we begonnen, deels zodat we de mensen om ons heen op de
                    hoogte kunnen houden.</p>

                <p><em>
                    <small>*Ik heb deze site niet zelf gemaakt, het is gewoon een blogspot blogje. Zelf maken heeft hier
                        geen
                        toegevoegde waarde, zeker niet in relatie tot de tijd die dat zou kosten.
                    </small>
                </em></p>
            </>
        },
        mealassist: {
            name: "MealAssist",
            url: "https://meal-assist.martijnvooges.nl",
            image: "mealassist.png",
            description: "App om lekkere maaltijden te bewaren voor later",
            content: <>
                <p>
                    Regelmatig komt het voor dat ik in de winkel sta en niet echt precies weet waar ik zin in heb en niet
                    echt iets kan verzinnen.<br/>
                    Ook denk ik wel eens "Goh, dit vind ik wel lekker, dit zou ik moeten onthouden", om het vervolgens
                    direct te vergeten.
                </p>
                <p>
                    Deze pagina is de oplossing daarvoor. Lekker makkelijk een maaltijd aanmaken als je het lekker vindt,
                    zodat je later makkelijk inspiratie op kan doen. Geen kookboeken, recepten of inspiratie-sites, maar
                    gewoon een lijst dingen waarvan je weet dat je het lust.
                </p>
                <p>Het is op moment van schrijven gewoon een privé app, maar het is publiekelijk bruikbaar mocht iemand het
                    leuk vinden. Op het moment ben ik eerst in mn eigen kring feedback aan het verkrijgen.
                </p>
            </>
        },
        berriedAlive: {
            name: "Berried Alive single page",
            url: "https://berried-alive.martijnvooges.nl",
            image: "berried-alive.png",
            description: "Single-page oefening voor CSS effecten",
            content: <>
                <p>
                    <a href={"https://berriedalive.bandcamp.com/"} target={"_blank"}>Berried Alive</a> is een zware, iets
                    duistere metal
                    band <small>(disclaimer: Als je niet van metal houdt, dit vooral <em>niet</em> luisteren!)</small>.
                </p>
                <p>
                    Terwijl ik hun muziek aan het luisteren was keek ik ook naar hun album art*. Toen ik dat zag, kreeg ik
                    wat ideeën voor een kleine site. Ze hebben geen bandsite en daar ook geen behoefte aan, maar ik vond het
                    leuk om te kijken of wat ik bedacht had alsnog te maken.
                </p>
                <p>
                    Wat er gebeurd in de site is dat ik diverse lagen heb gemaakt, elk met een stuk detail erin. De
                    "diepste" laag beweegt minder snel dan de "bovenste", waardoor er een soort 3d effect ontstaat.<br/>
                    Verder zitten er wat CSS kleur effecten in waardoor de lagen aan kleur op elkaar reageren, zoals je
                    bijvoorbeeld in Photoshop 'multiply' kunt selecteren.
                </p>
                <p>
                    Verder vond ik het jammer dat de hele site grungy was, maar de Spotify speler niet. Ik heb wat onderzoek
                    gedaan en vond een manier waarop je een laag over alles heen kunt zetten, maar nog wel kunt klikken op
                    de elementen eronder. Ook zit er een kleine flikker in de website om het iets onverwachter te maken.
                </p>
                <p>
                    <small><em>*De beer die ik heb gekozen is gewoon een beer van het internet, die van hun echte album is
                        anders, net als het logo wat er boven staat.</em></small>
                </p>
            </>
        },
    }
;