import React from 'react';
import './App.css';
import {projects} from "./data/projects";
import {ActiveProduct} from "./molecule/ActiveProduct";
import {IntroBlock} from "./molecule/IntroBlock";
import {ProjectList} from "./molecule/ProjectList";

class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            activeProject: null
        }
    }

    render() {
        return (
            <div className="App">
                <header id="mainHeader">
                    :) &lt;- minimalistische weergave van Martijn.
                </header>
                <div id={"mainBody"}>

                    <ProjectList onClick={(project)=>this.setState({activeProject: project})} />

                    <div id={"itemDisplay"}>

                        {!this.state.activeProject && <>
                            <IntroBlock />
                        </>}
                        {this.state.activeProject && <ActiveProduct project={this.state.activeProject} />}
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
