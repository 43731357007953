import React from 'react';


export function IntroBlock() {
    function timeOfDayGreeting() {
        const hour = (new Date()).getHours();
        if (hour < 6) {
            return "Goedenacht";
        }
        if (hour < 12) {
            return "Goedemorgen";
        }
        if (hour < 18) {
            return "Goedemiddag";
        }
        return "Goedenavond";
    }

    return <>
        <h1>Martijn's portfolio</h1>
        <p>
            {timeOfDayGreeting()}! Dit is een site van Martijn Vooges. Ik ben een fullstack programmeur* en hier ben ik
            bezig enkele van mijn projecten te showcasen, voornamelijk zodat ik makkelijk bij mijn projecten kan. Feel
            free om ze ook eens te
            bezoeken.

        </p>
        <p>
            Momenteel is hier nog niet veel inhoud en zijn de sites die hier staan niet van spectaculair niveau, maar
            klik gerust op 1 van de projecten links om er hier een uitgebreidere uitleg over te krijgen.
        </p>

        <p>
            <small><em>* Ja ik werk met computers, nee ik kom je niet computer repareren.</em></small>
        </p>
    </>
}